import React from "react"
import { Helmet } from "react-helmet"
import { Skoleni } from "../components/goods/Skoleni"

const IndexPage = () => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Wellness.cz</title>
    </Helmet>
    <main>
      {/* Hero card */}
      <div className="bg-gray-200  ">
        <Skoleni />
      </div>
    </main>
  </>
)

export default IndexPage
